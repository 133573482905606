<template>
  <app-layout>
    <div class="px-5 container container-center">
      <div class="heading d-flex justify-content-between mb-5">
        <h2 v-if="editMode">Editer la page</h2>
        <h2 v-else>Nouvelle page</h2>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-4">
            <router-link class="btn btn-link btn-sm" :to="{ name: 'pages.index' }" >
              <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
            </router-link>
          </div>
        </div>
      </div>

      <async-object
        :should-fetch="editMode"
        :fetch-method="fetchPage"
      >

        <div v-if="hasError" class="row">
          <div class="col-12">
            <div class="mb-4">
              <div class="alert alert-danger">
                Une erreur interne s'est produite. Réessayez dans quelques instants.
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="p-3 card">
              <v-dynamic-form
                :fields="fields"
                :initial-values="form"
                @change="handleChange"
                :errors="errors"
              ></v-dynamic-form>
              <div>
                <button class="btn btn-primary" :disabled="isSubmit" @click="submit">Enregistrer</button>
              </div>
            </div>
          </div>
        </div>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import AsyncObject from '@/components/common/AsyncObject'
import promptUnsaved from '@/mixins/promptUnsaved'
import useValidator from '@/mixins/useValidator'

export default {
  components: {
    AsyncObject
  },
  mixins: [promptUnsaved, useValidator],
  data () {
    return {
      hasError: false,
      isSubmit: false,
      isReady: false,
      form: {}
    }
  },
  computed: {
    editMode () {
      return !!this.$route.params.id
    },
    fields () {
      return [
        {
          name: 'name',
          label: 'Nom de la page',
          type: 'text',
          required: true
        },
        {
          name: 'slug',
          label: 'Slug',
          type: 'text',
          required: true
        },
        {
          name: 'content',
          label: 'Contenu de la page',
          type: 'page-editor',
          required: true
        }
      ]
    },
    validationRules () {
      return {
        name: 'required',
        slug: 'required|slug',
        content: 'required'
      }
    },
    page () {
      return this.$store.getters['pages/getDetailsById'](this.$route.params.id)
    }
  },
  methods: {
    handleChange (form) {
      if (this.isReady) {
        this.formStatusChanged(true)
      } else {
        this.isReady = true
      }
      this.form = form
    },
    async fetchPage () {
      await this.$store.dispatch('pages/fetchById', this.$route.params.id)
      this.form = this.page
    },
    async submit () {
      this.hasError = false
      this.isSubmit = true

      try {
        if (!this.validate(this.form, this.validationRules)) {
          this.isSubmit = false
          return
        }

        let id = null

        if (this.editMode) {
          await this.$store.dispatch('pages/update', { ...this.form, id: this.page.id })
        } else {
          id = (await this.$store.dispatch('pages/create', this.form)).id
          await this.$store.dispatch('pages/fetchById', id)
        }

        this.$toasted.success('Page enregistrée avec succès.')

        this.formStatusChanged(false)
        this.isSubmit = false

        if (id) {
          this.$router.push({ name: 'pages.edit', params: { id } })
        }
      } catch (e) {
        this.isSubmit = false

        if (e.response.status === 400) {
          this.errors = JSON.parse(e.response.data)
        } else {
          this.hasError = true
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
