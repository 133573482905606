<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Pages</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <div v-if="hasError" class="row">
          <div class="col-12">
            <div class="mb-4">
              <div class="alert alert-danger">
                Une erreur interne s'est produite. Réessayez dans quelques instants.
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4 d-flex justify-content-between">
          <div class="row">
            <div class="col-12">
              <div class="form-inline">
                <div class="form-group mobile-wid-100">
                  <input v-model="textFilter" type="text" class="form-control mobile-wid-100" placeholder="Filtrer...">
                </div>
              </div>
            </div>
          </div>

          <div>
            <button class="btn btn-primary" @click="$router.push({ name: 'pages.create' })">Ajouter</button>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body p-0">
            <div v-if="pages.length === 0" class="card-body text-center">
              <h4 class="header-title">Vous n'avez aucune page</h4>
            </div>
            <collection-table
              v-else
              :cols="cols"
              :list-items="filteredPages"
              :show-actions="true"
              @edit="editItem"
              @delete="deleteItem"
              no-top-border
              hover
              :link="(item) => `/pages/edit/${item.id}`"
            ></collection-table>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import { filter } from 'lodash-es'

export default {
  data () {
    return {
      hasError: false
    }
  },
  computed: {
    isLoading () {
      return this.$store.state.pages.isLoading
    },
    pages () {
      return this.$store.state.pages.collection
    },
    filteredPages () {
      let items = this.pages || []

      if (this.textFilter) {
        const searchString = this.textFilter.toLowerCase()

        items = filter(items, (item) => {
          return (
            item.name.toLowerCase().indexOf(searchString) > -1 ||
            item.slug.toLowerCase().indexOf(searchString) > -1
          )
        })
      }

      return items
    },
    cols () {
      return [
        {
          header: 'Nom',
          property: 'name'
        },
        {
          header: 'Slug',
          property: 'slug'
        }
      ]
    },
    textFilter: {
      get () {
        return this.$store.state.pages.textFilter
      },
      set (value) {
        this.$store.commit('pages/SET_TEXT_FILTER', value)
      }
    }
  },
  methods: {
    editItem (item) {
      this.$router.push({ name: 'pages.edit', params: { id: item.id } })
    },
    async deleteItem (item) {
      try {
        this.hasError = false

        await this.$modal.openConfirmModal({
          title: 'Supprimer la page',
          message: `Voulez-vous supprimer la page: ${item.name}?`,
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler'
        })

        this.$store.dispatch('pages/delete', item.id)
      } catch (error) {
        this.hasError = error !== 'ACTION_CANCELLED'
      }
    }
  },
  created () {
    this.$store.dispatch('pages/fetchOrRefresh')
  }
}
</script>
